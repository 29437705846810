import React, { useState } from "react"
import Img from "gatsby-image"
import { Cards, Card } from "../Cards"

import "./style.css"

const PortfolioPage = ({ portfolioCategories, portfolio, lang }) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const selectedCategory = portfolioCategories.list[selectedTab].label
  const selectedPortfolio = portfolio.filter(({ category }) => {
    if (typeof category === "string") return category === selectedCategory
    else return category.includes(selectedCategory)
  })

  return (
    <div className="portfolio-page">
      <div className="b-gallery-sorting-holder">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12">
              <ul className="list-inline text-uppercase text-center b-items-sort b-isotope__filter">
                {portfolioCategories.list.map(({ titleobj, label }, index) => (
                  <li className={selectedTab === index && "selected"}>
                    <span
                      className={`pointer ${selectedTab === index &&
                        "text-dark"}`}
                      onKeyPress={() => selectedTab(index)}
                      onClick={() => setSelectedTab(index)}
                    >
                      {titleobj[lang]}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="b-items-gallery-holder">
        <div className="container">
          <Cards gap="35px" cardWidth="340px">
            {selectedPortfolio.map(({ image, client }) => (
              <Card>
                <div className="gallery-item-content">
                  <div className="gallery-item-img">
                    <Img fluid={image?.childImageSharp.fluid || {}} />
                  </div>
                  <p className="item-category">{client[lang]}</p>
                </div>
              </Card>
            ))}
          </Cards>
        </div>
      </div>
    </div>
  )
}

export default PortfolioPage
