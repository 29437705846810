import React from "react"

import Layout from "../components/layout"
import PortfolioMainArea from "../components/PortfolioMainArea"
import PortfolioPage from "../components/PortfolioPage"
import { graphql } from "gatsby"
import portfolioCategories from "../../content/data/portfolio-categories.yml"
import Arrow from "../components/Arrow"

const Portfolio = ({
  data: {
    markdownRemark: {
      frontmatter: { title, description, lang },
    },
    portfolio: { edges },
  },
}) => {
  const portfolio = edges.map(({ node }) => node.frontmatter)
  return (
    <Layout title="Portfolio" lang={lang}>
      <PortfolioMainArea title={title} description={description} />
      <div className="b-homepage-content">
        <div className="b-layer-main">
          <Arrow />
          <PortfolioPage
            lang={lang}
            portfolio={portfolio}
            portfolioCategories={portfolioCategories}
          />
        </div>
      </div>
    </Layout>
  )
}
export default Portfolio

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        lang
        title
        description
      }
    }
    portfolio: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/(content/portfolio)/.*\\\\.md$/" }
      }
    ) {
      edges {
        node {
          frontmatter {
            titleobj {
              ar
              en
            }
            client {
              ar
              en
            }
            category
            image {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
